<template>
  <div class="widgetContainer cardManagement">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('details_title') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="cardInfo">
        <div class="cardInfo__card">
          <div class="card-front">
            <Card
              :card="getSelectedCard"
              :show-logo="false"
              :key="getSelectedCard.id"
              @update="onUpdate($event)"
              :show-secret-data="true" 
              @cardNumberDisplay="cardNumberDisplay"
              :show-activation-switch="true" />
          </div>
        </div>
      </div>

      <div
        class="listWithActions card-number-copy"
        v-if="getSelectedCard.cardType === 'virtual'">
        <ul class="main-actions  border-1">
          <li class="action-box card-number">
            <div class="details">
              <p class="label">
                {{ $t('cardInfo_row_title_CardNo') }}:
              </p>
              <div class="gray-text" ref="secret-data-card-number" />
            </div>
            <div class="action">
              <div ref="copy-btn" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2" @click="payHistory">
        <ul>
          <li class="action-box">
            <div class="details">
              <p class="label">
                {{ $t('cardInfo_row_title_transactions') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2">
        <h4 class="header-2">
          {{ $t('cardInfo_section_actions') }}
        </h4>
        <ul>
          <li class="cursor-auto">
            <div class="details">
              <p class="label">
                {{ $t('cardInfo_row_title_freeze') }}
              </p>
            </div>
            <div class="action">
              <el-switch
                @change="toggleFreeze"
                v-model="freezeCard"
                active-color="var(--branding)"
                inactive-color="#dfdfe4" />
            </div>
          </li>
          <li class="cursor-auto">
            <div class="details">
              <p class="label">
                {{ $t('card_label') }} :
                <span class="gray-text">{{ getSelectedCard.label }}</span>
              </p>
            </div>
            <div class="action action--text">
              <a @click="edit($event)" href="#">{{ $t('Edit') }}</a>
            </div>
          </li>
          <li class="cursor-auto">
            <div class="details">
              <p class="label">
                {{ $t('card_spendLimit') }} :
                <span class="green-text">{{ spendingLimit | currency }}</span>
              </p>
            </div>
            <div class="action action--text">
              <a @click="edit($event)" href="#">{{ $t('Edit') }}</a>
            </div>
          </li>

          <li class="cursor-auto" @click="atmInfo">
            <div class="details">
              <p class="label">
                {{ $t('info_ATM') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-arrow-right" />
            </div>
          </li>
          
          <li class="d-none" @click="replaceCard">
            <div class="icon">
              <span class="icon-paperplane" />
            </div>
            <div class="details">
              <p class="label">
                Replace card
              </p>
              <p class="value">
                You can request a new card here
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
          <li @click="deleteCard">
            <div class="details">
              <p class="label">
                {{ $t('cardInfo_row_title_cancel') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
          <li class="get-in-touch cursor-default" @click="openHelp">
            <div class="details">
              <p class="label">
                {{ $t('cardInfo_row_title_help') }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { cardStatus, cardType } from '../enums';
import Card from '../components/Card';

export default {
  name: 'CardManagement',
  components: {
    Card,
  },
  data() {
    return {
      freezeCard: false,
      flippedCard: false,
      cardNumber: ''
    };
  },
  created() {
    if (this.getSelectedCard) {
      this.freezeCard = this.getSelectedCard.cardStatus === 'inactive';
    }
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    ...mapGetters('user', ['getPersonDetails', 'getUserAuthToken']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    status() {
      return cardStatus[this.getSelectedCard.cardStatus];
    },
    spendingLimit() {
      return this.getSelectedCard.limitAmount;
    },
    type() {
      return cardType[this.getSelectedCard.cardType];
    }
  },
  methods: {
    ...mapActions('card', ['cardDelete', 'updateCard']),
    ...mapMutations('card', ['updateSelectedCard', 'updateReplaceCardDate']),
    ...mapMutations('transaction', ['updateFilters']),

    cancel() {
      this.drawerClose();
    },
    done() {
      this.drawerClose();
    },
    deleteCard() {
      this.$confirm(
        this.$t('cardInfo_cancel_alert_messsage'),
        this.$t('cardInfo_cancel_alert_title'),
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }
      )
        .then(() => {
          const loader = this.showLoader();
          this.cardDelete(this.getSelectedCard.id)
            .then(() => {
              setTimeout(() => {
                this.drawerClose();
                this.$emit('refresh');
                this.updateSelectedCard(null);
              }, 0);
            })
            .catch((e) => {
              console.log(e);
              this.apiErrorMessage(e);
            })
            .finally(() => {
              loader.close();
            });
        })
        .catch(() => {
        });
    },
    replaceCard() {
      this.drawerPush('ReplaceCardReason');
    },
    showDetails() {
      this.updateReplaceCardDate(null);
      this.drawerPush('CardDetails');
    },
    toggleFreeze() {
      this.$confirm(
        this.$t(
          this.freezeCard
            ? 'cardInfo_freeze_alert_messsage'
            : 'cardInfo_unfreeze_alert_messsage'
        ),
        this.$t(
          this.freezeCard
            ? 'cardInfo_freeze_alert_title'
            : 'cardInfo_unfreeze_alert_title'
        ),
        {
          confirmButtonText: this.freezeCard
            ? this.$t('cardInfo_freeze_button')
            : this.$t('cardInfo_unfreeze_button'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(() => {
          this.freeze();
        })
        .catch(() => {
          this.freezeCard = !this.freezeCard;
        });
    },
    freeze() {
      const loader = this.showLoader();
      this.updateCard({
        cardId: this.getSelectedCard.id,
        payload: {
          cardStatus: this.freezeCard ? 'inactive' : 'active'
        }
      }).then(() => {
        this.$emit('refresh');
      })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    onUpdate() {
      this.$emit('refresh');
    },

    payHistory() {
      this.updateFilters({
        cardId: this.getSelectedCard.id,
        contactId: null
      });
      this.$router.push('/transaction/list');
    },
    time() {
      return new Date().getTime();
    },
    edit(e) {
      e.preventDefault();
      this.drawerPush('CardSpendingForm');
    },
    cardNumberDisplay({show, headers}) {
      /**
       * card number
       */
      const cardNoiframe = show.request({
        name: 'cardNoIframe2',
        method: 'GET',
        headers,
        path: `/v1/card/${this.getSelectedCard.id}/show`,
        jsonPathSelector: 'cardNumber',
        serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
      });
      cardNoiframe.render(this.$refs['secret-data-card-number'], { color: 'gray', fontSize: '16px', display: 'block', height: '16px'});

      const copyButton = show.copyFrom(cardNoiframe, { text: '<svg width="13" height="13" viewBox="0 0 30 30" style="/* margin-left: calc(50% - 0.45849609375em) */"><g transform="scale(0.03125 0.03125)"><path d="M373.336 832c-99.969 0-181.336-81.374-181.336-181.338v-437.328h-74.664c-64.726 0-117.336 52.601-117.336 117.328v575.997c0 64.734 52.61 117.342 117.336 117.342h533.331c64.725 0 117.333-52.608 117.333-117.342v-74.658h-394.664z"></path><path d="M938.667 117.336c0-64.812-52.523-117.336-117.333-117.336h-447.997c-64.812 0-117.336 52.524-117.336 117.336v533.328c0 64.813 52.524 117.336 117.336 117.336h447.997c64.811 0 117.333-52.523 117.333-117.336v-533.328z"></path></g></svg>' }, (status) => {
        if (status === 'success') {
          let messagePrefix = this.$t('card');
          this.$notify({
            title: 'Success',
            message: `${messagePrefix} copied`,
            type: 'success'
          });
        }
      });

      copyButton.render(this.$refs['copy-btn'], { 
        cursor: 'pointer',
        overflow: 'hidden',
        left: 'auto',
        right: '0',
        width: 'auto'
      });
    },
    atmInfo() {
      this.drawerPush('AtmInfo');
    }
  },
  watch: {
    getSelectedCard: {
      handler(card) {
        if(card) {
          this.freezeCard = card.cardStatus === 'inactive';
        }
      }
    }
  }
};
</script>

<style lang="scss">
  .cardManagement {
    .widgetContainer__body {
      overflow-y: auto;
    }

    .sub-header {
      color: #000;
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }

    .listWithActionsv2 ul .get-in-touch:hover {
      .value {
        color: #fff;
      }
    }

    .cardInfo {
      margin-bottom: 24px;

      &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;

        .label {
          font-size: 13px;
          font-weight: 600;
        }

        .status {
          font-size: 14px;

          &--active {
            color: var(--success-color);
          }

          &--inactive {
            color: red;
          }
        }
      }

      &__card {
        .card-back {
          width: 48%;
        }

        .card {
          width: 100%;
        }

        &__info {
          padding-bottom: 21px;

          &__copy {
            left: auto;
            right: 0;
          }
        }
      }
    }

    .d-none {
      display: none !important;
    }

    .card-number-copy {
      
      ul {
        margin-bottom: 12px;
        padding: 0 24px;
      }

      li {
        padding: 12px 0;

        &.card-number {
          .details {
            display: flex;
            margin-left: 0;

            .label {
              white-space: nowrap;
              padding-right: 5px;
            }

            .gray-text {
              display: flex;
              width: 200px;
            }
          }

          .action {
            cursor: pointer;
          }
        }
      }
    }

    .main-actions {
      li {
        border-bottom: 1px solid #eeeeee;
        margin: 0;
        padding: 21px 0;

        &:last-child {
          border: none;
        }

        .gray-text {
          color: gray;
        }

        .green-text {
          color: var(--color-green);
        }

        .action {

          a,
          .link {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
            color: var(--branding-primary);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .card-number {
    iframe {
      height: 20px;
      width: 100%;
    }
  }
</style>
